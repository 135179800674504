import { BaseAPI } from '../base';
import { UpdateCreateFeedbackDto } from '../models';

export class FeedbackApi extends BaseAPI {
  readonly localVarPath: string = '/api/feedback';

  public feedbackGetItems() {
    return this.axiosParamCreator({
      methodApi: 'GET',
      urlMethod: this.localVarPath,
    });
  }

  public feedbackCreate(body: UpdateCreateFeedbackDto) {
    return this.axiosParamCreator({
      methodApi: 'POST',
      urlMethod: `${this.localVarPath}/create`,
      body,
    });
  }

  public feedbackUpdate(body: UpdateCreateFeedbackDto, id: number) {
    return this.axiosParamCreator({
      methodApi: 'PUT',
      urlMethod: `${this.localVarPath}/${id}`,
      body,
    });
  }

  public deleteFeedback(id: number) {
    return this.axiosParamCreator({
      methodApi: 'DELETE',
      urlMethod: `${this.localVarPath}/${id}`,
    });
  }

  public updatePositionFeedback(body: any) {
    return this.axiosParamCreator({
      methodApi: 'POST',
      urlMethod: `${this.localVarPath}/positions`,
      body,
    });
  }

  public feedbackUploadImage(image: string, id: number) {
    return this.axiosParamCreator({
      methodApi: 'POST',
      urlMethod: `${this.localVarPath}/${id}/image`,
      image,
    });
  }
}
