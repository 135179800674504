<template>
  <q-page>
    <div class="row full-width">
      <q-table
          :columns="columns"
          :rows="store.state.feedback.data"
          :rows-per-page-options="[lengthData]"
          class="full-width"
          hide-bottom
          row-key="index"
          title="Отзывы"
          @request="getFeedbacks">
        <template v-slot:top-right>
          <q-btn :to="$route.fullPath + '/addedit'" color="accent" icon="add" label="Добавить" size="sm"/>

          <q-btn :disabled="store.state.feedback.positions.length === 0" color="accent" icon="done"
                 label="Сохранить расположение"
                 size="sm"
                 @click="savePosition"/>
        </template>
        <template v-slot:body="props">

          <q-tr :id="props.row.position" :props="props" draggable="true" @dragend="droped($event)"
                @dragover="dragover($event, props.row)" @dragstart="dragit($event, props.row)">
            <q-td class="text-center">{{ props.row.id }}</q-td>

            <q-td class="text-left">
              <q-img :src="props.row.icon_url" fit="contain" loading="lazy" style="width: 80px; height: 80px;">
              </q-img>
            </q-td>

            <q-td class="text-center">{{ props.row.user_name }}</q-td>
            <q-td class="text-center">
              <long-text-field :maxlen="30" :value="props.row.comment"/>
            </q-td>
            <q-td class="text-center">{{ props.row.rating }}</q-td>
            <q-td class="text-center">{{ $filters.toHumanDate(props.row.date) }}</q-td>
            <q-td class="text-center">{{ props.row.position }}</q-td>
            <q-td class="text-center">
              <q-btn
                  :to="$route.fullPath +'/addedit/'+props.row.id"
                  dense
                  icon="edit"
                  round
                  size="sm"
                  @click="addEditRedirect(props.row)">
                <q-tooltip>Редактировать</q-tooltip>
              </q-btn>
              <q-btn
                  dense
                  icon="delete"
                  round
                  size="sm"
                  @click="deleteFeedback(props.row.id)">
                <q-tooltip>Удалить</q-tooltip>
              </q-btn>

            </q-td>
          </q-tr>

        </template>

      </q-table>
    </div>
  </q-page>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { FeedbackApi } from '@/api/apis/feedback-api.ts';
import { useStore } from 'vuex';
import LongTextField from '@/components/text/longTextField';
import { Notify } from 'quasar';
import $filters from '../../helpers/filters';

const columns = [
  {
    name: 'id',
    field: 'id',
    align: 'center',
    label: 'Id',
  },
  {
    name: 'icon_url',
    field: 'icon_url',
    align: 'left',
    label: 'Иконка пользователя',
  },
  {
    name: 'user_name',
    field: 'user_name',
    label: 'Имя пользователя',
    align: 'center',
  },
  {
    name: 'comment',
    field: 'comment',
    align: 'center',
    label: 'Комментарий',
  },
  {
    name: 'rating',
    field: 'rating',
    align: 'center',
    label: 'Рейтинг',
  },
  {
    name: 'date',
    field: 'date',
    align: 'center',
    label: 'Дата',
  },
  {
    name: 'position',
    field: 'position',
    align: 'center',
    label: 'Позиция',
  },
  {
    name: 'Action',
    label: '',
    field: 'Action',
    sortable: false,
    align: 'left',
  },
];

export default defineComponent({
  computed: {
    $filters() {
      return $filters;
    },
  },
  components: { LongTextField },
  setup() {
    const store = useStore();
    const moveItem = ref(null);
    const loading = ref(false);
    const lengthData = ref(0);

    async function getFeedbacks() {
      loading.value = true;
      const response = await new FeedbackApi().feedbackGetItems();
      store.dispatch('updateFeedbacks', response.data);
      lengthData.value = response.data.length;
      loading.value = false;
    }

    onMounted(() => getFeedbacks());

    const dragit = (event, item) => {
      moveItem.value = item;
    };

    const dragover = (e, item) => {
      const cloneFeedbacks = [...store.state.feedback.data];
      const ItemIndex = cloneFeedbacks.indexOf(moveItem.value);
      const woveItemIndex = cloneFeedbacks.indexOf(item);
      cloneFeedbacks.splice(ItemIndex, 1);
      cloneFeedbacks.splice(woveItemIndex, 0, moveItem.value);
      store.dispatch('updateFeedbacks', cloneFeedbacks);
    };
    const droped = () => {
      store.dispatch('updateFeedbacksDropped', store.state.feedback.data);
    };

    const addEditRedirect = (item) => {
      store.dispatch('editFeedbackItemUpdate', item);
    };

    async function deleteFeedback(id) {
      const response = await new FeedbackApi().deleteFeedback(id);
      if (response.status === 202) {
        store.dispatch('deleteFeedbackItem', id);
      }
    }

    async function savePosition() {
      const response = await new FeedbackApi().updatePositionFeedback(store.state.feedback.positions);
      if (response.status === 202) {
        store.dispatch('clearFeedbackPositions');
        Notify.create({
          type: 'positive',
          message: 'Сохранено',
        });
      }
    }

    return {
      lengthData,
      addEditRedirect,
      deleteFeedback,
      savePosition,
      dragover,
      dragit,
      droped,
      getFeedbacks,
      columns,
      store,

    };
  },
});
</script>
<style>
.q-btn {
  margin-left: 10px;
}
</style>
